/* eslint-disable no-undef */
import '../styles/index.scss';
import '@zolteam/axenergie-ui-library/dist/index.css';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import TagManager from 'react-gtm-module';
import Script from 'next/script';
import Head from 'next/head';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();
  const [tarteaucitronLoaded, setTarteaucitronLoaded] = useState(false);

  useEffect(() => {
    if (tarteaucitronLoaded) {
      tarteaucitron.init({
        privacyUrl: `https://${process.env.NEXT_PUBLIC_DOMAIN}/politique-de-confidentialite`,
        hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
        cookieName: 'tarteaucitron', /* Cookie name */
        orientation: 'top', /* Banner position (top - bottom - middle - popup) */
        groupServices: false, /* Group services by category */
        showAlertSmall: true, /* Show the small banner on bottom right */
        cookieslist: false, /* Show the cookie list */
        showIcon: false, /* Show cookie icon to manage cookies */
        // "iconSrc": "", /* Optionnal: URL or base64 encoded image */
        iconPosition: 'BottomRight',
        /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */
        adblocker: false, /* Show a Warning if an adblocker is detected */
        DenyAllCta: true, /* Show the deny all button */
        AcceptAllCta: true, /* Show the accept all button when highPrivacy on */
        highPrivacy: true, /* HIGHLY RECOMMANDED Disable auto consent */
        handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */
        removeCredit: false, /* Remove credit link */
        moreInfoLink: true, /* Show more info link */
        useExternalCss: false, /* If false, the tarteaucitron.css file will be loaded */
        // "cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */
        readmoreLink: '', /* Change the default readmore link pointing to tarteaucitron.io */
        mandatory: true, /* Show a message about mandatory cookies */
      });
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_TRACKING_ID });
    }
  }, [tarteaucitronLoaded]);

  useEffect(() => {
    if (tarteaucitronLoaded) {
      setTimeout(() => {
        if (!window) return;

        window.dataLayer.push({
          event: 'VirtualPageView',
          path: router.asPath,
          title: document.title,
        });
      }, 0);
    }
  }, [router.asPath, tarteaucitronLoaded]);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Head>
          <meta name="google-site-verification" content="OgJg_Kxd7j7Vn5cN0frQKYCdnO9VFX3EM0aI1zyCGAY" />
          <meta name="facebook-domain-verification" content="w60x4k358614fz7o8do7hcthv031b8" />
          <link
            rel="apple-touch-icon"
            href={`https://${process.env.NEXT_PUBLIC_DOMAIN}/Images/homescreen-logo.webp`}
          />
          <link rel="canonical" href={`https://${process.env.NEXT_PUBLIC_DOMAIN}${router.asPath}`} />
        </Head>
        <Script
          id="tarteaucitronscript"
          src="https://cdn.jsdelivr.net/gh/AmauriC/tarteaucitron.js@1.9.5/tarteaucitron.js"
          onLoad={() => {
            setTarteaucitronLoaded(true);
          }}
        />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...pageProps} />
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
