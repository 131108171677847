import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://c2bf907c63ac4114977819a4c3327676@o125949.ingest.sentry.io/6147771',
  tracesSampleRate: 0,
  ignoreErrors: [
    'Non-Error promise rejection captured',
    '/^ResizeObserver loop limit exceeded$/',
    '/^ResizeObserver loop completed with undelivered notifications.$/',
  ],
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
});
